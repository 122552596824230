import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import Button from '@/components/global/Button'
import Link from '@/utils/Link'

function Row({ children }) {
	return <div className="mb-12">{children}</div>
}

function Heading({ children }) {
	return <h4 className="mb-6 text-h4-fluid">{children}</h4>
}

function Content({ children, ...props }) {
	return (
		<div className="mb-6" {...props}>
			{children}
		</div>
	)
}

function ContactPanel({ className }) {
	return (
		<div className={classNames(className, 'wrapper')}>
			<div className="lg:flex lg:justify-between">
				<div className="mb-8 lg:mb-0 lg:w-1/2 lg:pr-16">
					<img
						src="/images/w2b-tracking.png"
						width="650 "
						height=" 520"
						alt=""
						loading="lazy"
					/>
				</div>
				<div className="max-w-lg lg:w-1/2">
					<Row>
						<Heading>Sales</Heading>
						<Content>
							Evaluating our products and services and need advice? Get in touch
							with our sales team to see how we can work together.
						</Content>
						<Button as={Link} to="/enterprise/">
							Visit Enterprise
						</Button>
					</Row>
					<Row>
						<Heading>Career</Heading>
						<Content>
							Want to look at our current job listings? Search our latest roles
							and submit your application.
						</Content>
						<Button as={Link} to="/company/careers/">
							Visit Careers
						</Button>
					</Row>
					<Row>
						<Heading>Press</Heading>
						<Content>
							Are you interested in our latest news or working on an Ultraleap
							story and need to get in touch? Visit our press page to download
							our brand pack including logos and product shots, or to get in
							touch.
						</Content>
						<Button as={Link} to="/press-kit/">
							Visit Press Page
						</Button>
					</Row>
					<Row>
						<Heading>Support</Heading>
						<Content>
							Already using our products and experiencing technical issues? Find
							helpful resources on our developer sites, or raise a support
							ticket and our team will get back to you.
						</Content>
						<div className="flex">
							<Button
								className="mr-8"
								as={Link}
								to="https://support.leapmotion.com"
							>
								Hand Tracking
							</Button>

							<Button as={Link} to="https://developer.ultrahaptics.com/knowledgebase/support-form/">
								Haptics
							</Button>
						</div>
					</Row>
				</div>
			</div>
		</div>
	)
}

ContactPanel.propTypes = {
	className: string
}

export default ContactPanel
