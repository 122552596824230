import React, { useEffect, useRef, useState, useCallback, memo } from 'react'
import { string } from 'prop-types'

function Form({
	form_name = 'Contact Form',
	form_id = 'https://www2.ultraleap.com/l/727963/2019-09-18/25v4s',
	form_height
}) {
	const height = form_height || 230
	const form_name_cleaned = form_name.replace(/\s/g, '')
	const iframe = useRef()
	const [style, setStyle] = useState({ minHeight: +height })

	const onLoad = useCallback(() => {
		setStyle({ minHeight: +height  })
	}, [])

	const onMessage = useCallback(event => {
		if (event.origin === 'https://www2.ultraleap.com' && event.data === 50) {
			setTimeout(() => {
				setStyle({ height: 230 })
			})
		}
	}, [])

	useEffect(() => {
		const frame = iframe.current
		if (window) {
			frame.addEventListener('load', onLoad)
			window.addEventListener('message', onMessage)
		}

		return () => {
			frame.removeEventListener('load', onLoad)
			window.removeEventListener('message', onMessage)
		}
	}, [onLoad, onMessage])

	return (
		<iframe
			id={form_name_cleaned}
			ref={iframe}
			title={form_name}
			src={form_id}
			style={style}
			className="overflow-hidden iframe min-h-48"
			scrolling="no"
			seamless="seamless"
			width="100%"
			height="auto"
			marginHeight="0"
			marginWidth="0"
			frameBorder="0"
			horizontalscrolling="no"
			verticalscrolling="no"
		/>
	)
}

Form.propTypes = {
	form_name: string,
	form_id: string
}

export default memo(Form)
