/* eslint-disable react/no-danger */
import React from 'react'
import { string } from 'prop-types'
import SectionHeading from '@/components/global/SectionHeading'
import Button from '@/components/global/Button'

function Location({ addresss, email, map_link, region = 'UK', phone }) {
	const emailAdd = email.toLowerCase()

	return (
		<div className="flex flex-col pb-10 pr-0 text-left footer-col md:w-1/2 md:pr-6 md:pb-0">
			<SectionHeading size="w-10" className="mb-10 text-sm text-darkgrey">
				{region}
			</SectionHeading>
			<p
				className="mb-6 whitespace-pre text-darkgrey text-h4-fluid lg:mb-10"
				dangerouslySetInnerHTML={{ __html: addresss }}
			/>
			<div className="mt-auto mb-8 lg:mb-10">
				<a
					className="block phone text-secondary text-h4-fluid"
					href={`tel:${phone}`}
				>
					{phone}
				</a>
				<a
					className="block email text-secondary text-h4-fluid"
					href={`mailto:${emailAdd}`}
				>
					{emailAdd}
				</a>
			</div>
			<div className="mt-auto">
				<Button
					as="a"
					className="font-bold"
					href={map_link}
					target="_blank"
					rel="noopener noreferrer"
				>
					Get Directions
				</Button>
			</div>
		</div>
	)
}

Location.propTypes = {
	addresss: string.isRequired,
	email: string.isRequired,
	map_link: string.isRequired,
	phone: string.isRequired,
	region: string.isRequired
}

export default Location
