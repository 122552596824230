import React from 'react'
import { string, shape } from 'prop-types'
import { Helmet } from 'react-helmet-async'

function Seo({
	canonical,
	twitter_title,
	twitter_image,
	twitter_description,
	// redirect,
	title,
	opengraph_title,
	opengraph_image,
	// metakeywords,
	opengraph_description,
	metadesc,
	meta_robots_noindex,
	meta_robots_nofollow,
	image
	// linkdex,
	// focuskw
}) {
	const heading = title ? `${title} | Ultraleap` : 'Ultraleap'
	const url = typeof window !== 'undefined' ? window.location.href : canonical

	const twitterImage = twitter_image
		? twitter_image.source_url
		: image
		? image.source_url
		: "https://cms.ultraleap.com/app/uploads/2020/02/northern-lights.jpg"

	const opengraphImage = opengraph_image
		? opengraph_image.source_url
		: image
		? image.source_url
		: null

	return (
		<Helmet>
			<title>{heading}</title>
			<meta name="description" content={metadesc} />
			{/* <meta name="keywords" content={metakeywords} /> */}
			<meta name="robots" content={`${meta_robots_noindex} ${meta_robots_nofollow}`} />
			<meta property="og:title" content={opengraph_title || heading} />
			<meta property="og:description" content={opengraph_description || metadesc} />
			{opengraphImage && <meta property="og:image" content={opengraphImage} />}
			<meta property="og:url" content={url} />
			<meta property="og:type" content="website" />
			<meta property="fb:app_id" content="316637035917873" />
			<meta name="twitter:creator" content="ultraleap" />
			<meta name="twitter:title" content={twitter_title || heading} />
			<meta name="twitter:description" content={twitter_description || metadesc} />
			{twitterImage && <meta name="twitter:image" content={twitterImage} />}
			{twitterImage && <meta name="twitter:image:src" content={twitterImage} />}
			<meta name="twitter:card" content="summary_large_image" />
			<link rel="canonical" href={url} />
		</Helmet>
	)
}

Seo.propTypes = {
	canonical: string,
	twitter_title: string,
	twitter_image: shape({
		source_url: string
	}),
	twitter_description: string,
	title: string,
	opengraph_title: string,
	opengraph_image: shape({
		source_url: string
	}),
	image: shape({
		source_url: string
	}),
	metakeywords: string,
	opengraph_description: string,
	metadesc: string,
	meta_robots_noindex: string,
	meta_robots_nofollow: string
	// redirect: string,
	// meta_robots_adv: string,
	// linkdex: string,
	// focuskw: string
}

export default Seo
