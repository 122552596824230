/* eslint-disable react/prop-types */
import React from 'react'
import Location from '@/components/Location'
import Form from '@/components/Form'

function ContactPageForm({ locations }) {
	return (
		<div className="overflow-hidden bg-lightgrey">
			<div className="px-8 wrapper">
				<div className="pt-20 mx-auto lg:flex lg:py-32 lg:-ml-16">
					<div className="w-full pr-10 mb-8 mb-12 lg:flex lg:flex-col lg:pl-16 lg:w-3/5">
						<h2 className="max-w-lg mb-12 leading-snug text-left text-darkgrey text-h4-fluid font-museoSans">
							Whether you’re looking to bring your next great idea to life or
							interested in finding out more about Ultraleap, we’re here to
							help.
						</h2>
						<div>
							<div className="md:flex">
								{locations.map(
									({
										wordpress_id,
										title,
										acf: {
											contact: { email, map_link, phone, addresss }
										}
									}) => (
										<Location
											key={wordpress_id}
											email={email}
											map_link={map_link}
											addresss={addresss}
											phone={phone}
											region={title === 'Bristol' ? 'UK' : 'US'}
										/>
									)
								)}
							</div>
						</div>
						<div className="mt-10">
							<p className="pb-4 text-left">
								By contacting us, you are agreeing to our{' '}
								<a
									className="underline pardotTrackClick text-primary"
									href="/privacy-policy/"
									rel="nofollow"
									target="_blank"
								>
									privacy policy
								</a>
								.
							</p>
						</div>
					</div>
					<div className="w-full lg:flex lg:flex-col mb lg:pl-16 lg:w-2/5">
						<Form />
						<div className="seamless-fallback">
							<em />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

// ContactPageForm.propTypes = {
// 	className: string
// }

export default ContactPageForm
