/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import { useLocation } from "@reach/router"
// import useMediaQuery from '@/hooks/useMediaQuery'
import Image from '@/utils/Image'
import Link from '@/utils/Link'
import IconWrapper from '@/utils/IconWrapper'
import Arrow from '@/icons/upwards-arrow.svg'
import stylesheet from './Hero.module.css';

function Hero({
	subheading,
	heading,
	background_video_desktop,
	background_type,
	background_image,
	background_image_small,
	hero_background_image
}) {
	const ref = useRef()
	const location = useLocation()

	const onClick = e => {
		e.preventDefault()

		const nextNode = document.getElementById('_row_1_')
		if (nextNode) {
			const { top } = nextNode.getBoundingClientRect()

			window.scroll({
				top: top - 40,
				behavior: 'smooth'
			})
		}
	}

	// protect against missing content in CMS
	const background_image_mobile = background_image_small || hero_background_image || background_image

	return (
		<>
			<div
				ref={ ref }
				className={ classNames('relative', {
					'aspect-ratio-5/9 vsm:aspect-ratio-square lg:pb-0 lg:h-hero': background_image || hero_background_image
				}) }
			>
				{ background_video_desktop && (
					<div className={ `relative z-10 w-full h-screen ${ stylesheet.videoContainer }` }>
						<video
							preload="auto"
							loop
							autoPlay
							muted
							playsInline
							src={ background_video_desktop.source_url || background_video_desktop }
							className="object-cover w-full h-full"
						/>
					</div>
				) }
				{ background_type === 'Image' && (
					<>
						<Image
							className="hidden object-cover sm:block"
							style={ { height: '100%', width: '100%', position: 'absolute' } }
							{ ...background_image || hero_background_image }
						/>
						<Image
							className="block object-cover sm:hidden"
							style={ { height: '100%', width: '100%', position: 'absolute' } }
							{ ...background_image_mobile }
						/>
						<div className="absolute inset-0 bg-black-50" />
					</>
				) }
				{/* {this.props.image ? this.props.image : null} */ }
				{ location.pathname === "/" && <div className={  stylesheet.opacity } /> }
				<div className="absolute inset-0 z-20 flex flex-col items-center justify-center max-w-6xl px-8 mx-auto text-center">
					<h1 className="mb-3 leading-tight text-white text-h1-fluid font-museoSansBold tracking-wide">
						{ heading }
					</h1>
					{ subheading && (
						<h4 className="text-white text-h4-fluid font-museoSansBold lg:pt-8">
							{ subheading }
						</h4>
					) }
					<Link
						to="#0"
						onClick={ onClick }
						className="flex-shrink-0 w-10 mt-8 md:w-16 rotate-180"
					>
						<IconWrapper
							className="w-full text-white stroke-current"
							label="Read more"
							icon={ Arrow }
						/>
					</Link>
				</div>
			</div>
		</>
	)
}

Hero.propTypes = {
	subHeading: string,
	heading: string,
	// background_video_desktop: object,
	background_type: string
}

export default Hero
