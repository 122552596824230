import React from 'react'
import { string, node, bool, number } from 'prop-types'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import Node from '@/utils/Node'

const variants = {
	open: { x: 0, transition: { duration: 0.5 } },
	closed: { x: '-100%' }
}

function SectionHeading({
	as = 'h3',
	children,
	className = 'mb-12 text-sm text-white',
	animate = false,
	threshold = 0,
	rootMargin = '-20px',
	size = 'w-20 lg:w-32',
	...rest
}) {
	const [ref, inView] = useInView({ threshold, rootMargin })
	const shouldAnimate = animate || inView

	return (
		<Node
			as={as}
			ref={ref}
			className={classNames(
				'flex items-center uppercase relative overflow-hidden',
				className,
				{ 'tracking-widest': !className.includes('tracking') }
			)}
			{...rest}
		>
			<motion.span
				variants={variants}
				animate={shouldAnimate ? 'open' : 'closed'}
				className={classNames('block h-1 bg-currentColor mr-4', size)}
			/>
			<span>{children}</span>
		</Node>
	)
}

SectionHeading.propTypes = {
	children: node.isRequired,
	className: string,
	animate: bool,
	as: string,
	rootMargin: string,
	size: string,
	threshold: number
}

export default SectionHeading
